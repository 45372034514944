import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Card,
  CardImg,
  CardTitle,
  Modal,
  Collapse,
} from "reactstrap"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as url from "helpers/url_helper"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import i18n from "i18n"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/ugifts/loginbg.png"
import bgProfile from "assets/images/ugifts/profile_bg.png"
import bgFriend from "assets/images/ugifts/friend_bg.png"
import inviteFriend from "assets/images/ugifts/invite_friend.png"
import iconPoint from "assets/images/ugifts/icons/profile_point.png"
import iconRibbon from "assets/images/ugifts/icon_ribbon.png"
import inviteFriendBg from "assets/images/ugifts/invite_friend_purple_bg.png"

import { Button } from "@material-ui/core"

import emptyLogin from "assets/images/ugifts/icons/empty/empty_login.png"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const languageData = [
  { name: "English", short: "eng" },
  { name: "Chinese", short: "cn" },
  { name: "Japanese", short: "jp" },
]

const DanawaTerms = props => {
  const query = useQuery()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [uid, setUid] = useState(null)
  const [basketCount, setBasketCount] = useState(null)
  const [isGuest, setIsGuest] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.id)
      console.log(obj)
    } else {
      setIsGuest(true)
    }

    if (localStorage.getItem("basketCount")) {
      setBasketCount(localStorage.getItem("basketCount"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (uid != null) {
      // if (!isGuest) {
        getUserProfile()
      //   getFriendList()
      //   getOrderList()
      //   getBasketData()
      //   getUdollar()
      // }
      console.log("use effect")
    }
  }, [uid])

  const [userData, setUserData] = useState(null)
  const getUserProfile = async () => {
    // var form = JSON.stringify({
    //   option: "detail_profile",
    //   id: uid,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_profile")
    urlencoded.append("id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   // "Content-Type": "application/json",
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("Request user profile", urlencoded)

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setUserData(postresponse.data)
    } else {
      // seterror_alert(true)
    }
  }

  const getOrderList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_order")
    urlencoded.append("member_id", uid)
    urlencoded.append("filter_by", "purchased")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request order list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_ORDER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  const [friendData, setFriendData] = useState(null)
  const getFriendList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_friend")
    urlencoded.append("order_by", "asc")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request category", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setFriendData(postresponse)
    } else {
      // seterror_alert(true)
    }
  }

  const getBasketData = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_basket")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request basket", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      var calculate = 0
      postresponse.table.products.forEach(product => {
        calculate += parseInt(product.quantity)
      })
      localStorage.setItem("basketCount", calculate)
      setBasketCount(calculate)
    } else {
    }
  }

  const getUdollar = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_udollar_giftcards")
    urlencoded.append("member_id", 52)
    urlencoded.append("currency_code", "MYR")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request udollar list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  function logout() {
    localStorage.removeItem("authUser")
    // localStorage.removeItem("basketCount")
    // localStorage.removeItem("fbToken")
    history.push("/login")
  }

  const [languageSelected, setlanguageSelected] = useState(false)
  const [modal_language, setmodal_language] = useState(false)
  function tog_language() {
    setmodal_language(!modal_language)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Terms & Conditions</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              // lg={10}
              // className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "white",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                // className="auth-full-page-content"
                // className="flex"
                style={{
                  display: "flex",
                  minHeight: "100vh",
                  // paddingTop: "56px",
                  paddingBottom: "105px",
                  // background: "#EBEBEB",
                }}
              >
                <div
                  className="w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    lineHeight: 1.8,
                  }}
                >
                  <div className="d-flex flex-column h-100 p-3">
                    <Row className="justify-content-center">
                      <Col xs="10">
                        <Row>
                          <Col>
                            <span className="font-size-30 font-weight-semibold">
                              {"Terms & Conditions for SRON"}
                            </span>
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"1. Introduction"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              These Terms & Conditions ("Terms") govern the use
                              of the SRON app and website, which is powered by
                              Danawa Resources Sdn Bhd. By accessing or using
                              SRON, you agree to comply with and be bound by
                              these Terms. If you do not agree with these Terms,
                              please do not use SRON.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"2. Purpose of SRON"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              SRON is an app powered by Danawa Resources Sdn Bhd
                              that provides users with easy access to a range of
                              internet service products, including Free Public
                              Wi-Fi, Public Connect, Home Connect, and Corporate
                              Connect. Through the app, users can purchase
                              internet vouchers for these services. SRON is
                              designed to serve both rural and urban areas, with
                              a particular focus on providing internet coverage
                              to rural areas that currently have no existing
                              internet services.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"3. Account Creation"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              To use SRON, users are required to create an
                              account by providing a valid email address and
                              setting a password. You may also sign in using
                              your Apple ID, Facebook, or Google account. By
                              registering, you agree to provide accurate and
                              complete information, and you are responsible for
                              keeping your login credentials confidential.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"4. Subscriptions and Payments"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              SRON offers paid features or services, such as
                              internet service products and vouchers. Payments
                              are processed through a secure payment gateway,
                              including FPX and Spay Global. By subscribing or
                              making a purchase, you agree to pay all applicable
                              fees associated with the services provided.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"5. Use of SRON"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              You agree to use SRON only for lawful purposes and
                              in accordance with these Terms. You must not use
                              SRON to:
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                Violate any applicable laws or regulations;
                              </li>
                              <li>
                                Infringe on the intellectual property rights of
                                others;
                              </li>
                              <li>
                                Engage in harassment, spamming, or any other
                                malicious activity.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"6. Content"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              SRON does not currently allow users to upload or
                              share content, such as photos or videos. However,
                              this feature may be introduced in the future, and
                              if so, users will be notified of any changes.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"7. Third-Party Services"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              SRON may integrate with third-party services,
                              including payment processors like FPX, Spay
                              Global, and social media sign-in services like
                              Apple ID, Facebook, and Google. By using SRON, you
                              consent to the sharing of necessary data with
                              these third parties for the purposes of processing
                              payments and authentication.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"8. Data Protection and Privacy"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              SRON is committed to protecting your privacy. For
                              details on how we collect, use, and protect your
                              personal data, please refer to our Privacy Policy.
                              We comply with the Personal Data Protection Act
                              2010 (PDPA) of Malaysia, and your personal data
                              will be processed in accordance with this law.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"9. Data Retention"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              We retain your personal information for as long as
                              necessary to fulfill the purposes for which it was
                              collected, such as processing payments and
                              providing ongoing support. You can request the
                              deletion of your personal data at any time by
                              contacting us.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"10. Security of Your Information"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              We use industry-standard security measures to
                              protect your personal information. However, no
                              method of data transmission over the internet is
                              100% secure, and we cannot guarantee absolute
                              security.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"11. Limitation of Liability"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              SRON and Danawa Resources Sdn Bhd shall not be
                              held liable for any indirect, incidental, or
                              consequential damages arising from the use or
                              inability to use SRON, including but not limited
                              to loss of data, loss of profits, or business
                              interruption.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"12. Changes to Terms & Conditions"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              SRON reserves the right to modify these Terms &
                              Conditions at any time. Any changes will be posted
                              within the app or on our website, and users are
                              encouraged to review these Terms periodically.
                              Continued use of SRON after such changes indicates
                              your acceptance of the new terms.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"13. Contact Us"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              If you have any questions or concerns about these
                              Terms & Conditions, please contact us at
                              support@danawa.com.my or 1300-88-22-30. Please
                              note that our contact details may change from time
                              to time, and we will update them accordingly.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"14. Governing Law"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              These Terms & Conditions shall be governed by and
                              construed in accordance with the laws of Malaysia.
                              Any disputes arising under or in connection with
                              these Terms will be subject to the exclusive
                              jurisdiction of the courts of Malaysia.
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaTerms)

DanawaTerms.propTypes = {
  t: PropTypes.any,
}
