import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Card,
  CardImg,
  CardTitle,
  Modal,
  Collapse,
} from "reactstrap"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as url from "helpers/url_helper"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import i18n from "i18n"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/ugifts/loginbg.png"
import bgProfile from "assets/images/ugifts/profile_bg.png"
import bgFriend from "assets/images/ugifts/friend_bg.png"
import inviteFriend from "assets/images/ugifts/invite_friend.png"
import iconPoint from "assets/images/ugifts/icons/profile_point.png"
import iconRibbon from "assets/images/ugifts/icon_ribbon.png"
import inviteFriendBg from "assets/images/ugifts/invite_friend_purple_bg.png"

import { Button } from "@material-ui/core"

import emptyLogin from "assets/images/ugifts/icons/empty/empty_login.png"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const languageData = [
  { name: "English", short: "eng" },
  { name: "Chinese", short: "cn" },
  { name: "Japanese", short: "jp" },
]

const DanawaFAQ = props => {
  const query = useQuery()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [uid, setUid] = useState(null)
  const [basketCount, setBasketCount] = useState(null)
  const [isGuest, setIsGuest] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.id)
      console.log(obj)
    } else {
      setIsGuest(true)
    }

    if (localStorage.getItem("basketCount")) {
      setBasketCount(localStorage.getItem("basketCount"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (uid != null) {
      // if (!isGuest) {
        getUserProfile()
      //   getFriendList()
      //   getOrderList()
      //   getBasketData()
      //   getUdollar()
      // }
      console.log("use effect")
    }
  }, [uid])

  const [userData, setUserData] = useState(null)
  const getUserProfile = async () => {
    // var form = JSON.stringify({
    //   option: "detail_profile",
    //   id: uid,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_profile")
    urlencoded.append("id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   // "Content-Type": "application/json",
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("Request user profile", urlencoded)

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setUserData(postresponse.data)
    } else {
      // seterror_alert(true)
    }
  }

  const getOrderList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_order")
    urlencoded.append("member_id", uid)
    urlencoded.append("filter_by", "purchased")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request order list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_ORDER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  const [friendData, setFriendData] = useState(null)
  const getFriendList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_friend")
    urlencoded.append("order_by", "asc")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request category", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setFriendData(postresponse)
    } else {
      // seterror_alert(true)
    }
  }

  const getBasketData = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_basket")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request basket", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      var calculate = 0
      postresponse.table.products.forEach(product => {
        calculate += parseInt(product.quantity)
      })
      localStorage.setItem("basketCount", calculate)
      setBasketCount(calculate)
    } else {
    }
  }

  const getUdollar = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_udollar_giftcards")
    urlencoded.append("member_id", 52)
    urlencoded.append("currency_code", "MYR")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request udollar list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  function logout() {
    localStorage.removeItem("authUser")
    // localStorage.removeItem("basketCount")
    // localStorage.removeItem("fbToken")
    history.push("/login")
  }

  const [languageSelected, setlanguageSelected] = useState(false)
  const [modal_language, setmodal_language] = useState(false)
  function tog_language() {
    setmodal_language(!modal_language)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | FAQ</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              // lg={10}
              // className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "white",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                // className="auth-full-page-content"
                // className="flex"
                style={{
                  display: "flex",
                  minHeight: "100vh",
                  // paddingTop: "56px",
                  paddingBottom: "105px",
                  // background: "#EBEBEB",
                }}
              >
                <div
                  className="w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    lineHeight: 1.8,
                  }}
                >
                  <div className="d-flex flex-column h-100 p-3">
                    <Row className="justify-content-center">
                      <Col xs="10">
                        <Row>
                          <Col>
                            <span className="font-size-30 font-weight-semibold">
                              {"SRON FAQ"}
                            </span>
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"1. How do I check SRON coverage in my area?"}
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                To check SRON coverage, open the app and go to
                                the
                                <strong> Free Wi-Fi section</strong>. Click on
                                the
                                <strong> Check Coverage</strong> button, enter
                                your location, and the app will display whether
                                SRON's coverage is available in your area.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {
                                "2. What are the different types of packages available in SRON?"
                              }
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                <strong>Free Wi-Fi:</strong> Offers
                                complimentary internet access in specific
                                coverage areas.
                              </li>
                              <li>
                                <strong>Public Connect:</strong> A paid voucher
                                offering 1-month internet access (RM39).
                              </li>
                              <li>
                                <strong>Home Connect:</strong> A service for
                                home internet, where users can register by
                                filling out an order form for installation.
                              </li>
                              <li>
                                <strong>Corporate Connect:</strong> A service
                                for businesses, where users can register by
                                filling out a form with company details,
                                followed by a site survey.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"3. How do I register for Home Connect?"}
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                To register for <strong>Home Connect</strong>,
                                go to the <strong>Home Connect</strong> section
                                in the app and click on{" "}
                                <strong>Register Now</strong>. Fill out the{" "}
                                <strong>Order Form</strong> with your full name,
                                IC number, address, and contact information. The
                                form will be processed through the CRM system
                                for installation.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {
                                "4. Can I use the same voucher for multiple devices?"
                              }
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                <strong>1 Voucher = 1 Device:</strong> <br />
                                The voucher purchased through{" "}
                                <strong>Public Connect</strong> is valid for
                                only
                                <strong> one device</strong>. Each voucher can be
                                used on a single device at a time. If you need
                                to connect additional devices, you will need to
                                purchase separate vouchers for each device.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {
                                "5. What should I do if I have trouble connecting to SRON Wi-Fi?"
                              }
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                If you are having trouble connecting to SRON
                                Wi-Fi:
                                <ol>
                                  <li>
                                    Ensure you are in a covered area (use the
                                    <strong> Check Coverage</strong> button).
                                  </li>
                                  <li>
                                    Make sure your device is connected to the
                                    correct SRON network.
                                  </li>
                                  <li>
                                    Restart your device or reconnect to the
                                    Wi-Fi.
                                  </li>
                                  <li>
                                    If the issue persists, contact SRON support
                                    through the <strong>Contact Us</strong>{" "}
                                    section in the app for further assistance.
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"6. How do I update my profile details?"}
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                To update your profile details:
                                <ol>
                                  <li>
                                    Go to the <strong>Profile</strong> section
                                    of the app.
                                  </li>
                                  <li>
                                    Click on <strong>Edit Profile</strong> to
                                    change personal details like your email,
                                    password, or contact information.
                                  </li>
                                  <li>
                                    Once updated, click <strong>Save</strong> to
                                    confirm the changes.
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {
                                "7. How can I purchase a voucher for Public Connect?"
                              }
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                To purchase a voucher for{" "}
                                <strong>Public Connect</strong>, navigate to the
                                <strong> Public Connect</strong> section, and
                                click on the <strong>Purchase Voucher</strong>{" "}
                                button. Select the payment option (FPX or Spay),
                                complete the transaction, and the app will
                                generate a Voucher Code for you.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {
                                "8. How long is the validity of a Public Connect voucher?"
                              }
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                A voucher for <strong>Public Connect</strong> is
                                valid for <strong>1 month</strong> after
                                activation. Once activated, you can use it for
                                internet access within that timeframe.
                              </li>
                              We use cookies to enhance your app experience. You
                              can manage cookie preferences through your browser
                              settings.
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {
                                "9. How do I contact SRON for support or inquiries?"
                              }
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                You can contact SRON support directly through
                                the <strong>FAQ</strong> or{" "}
                                <strong>Contact Us</strong> section in the app.
                                For corporate inquiries, the{" "}
                                <strong>Corporate Connect </strong>
                                section allows you to register and get in touch
                                with the Sales & Marketing team.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {
                                "10. What payment methods are available for purchasing vouchers?"
                              }
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                You can pay for <strong>Public Connect</strong>{" "}
                                vouchers using <strong>FPX</strong> (Financial
                                Process Exchange) or <strong>Spay</strong>{" "}
                                (payment gateway). Choose your preferred payment
                                method at checkout.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {
                                "11. Can I modify or cancel my Home Connect order?"
                              }
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                Once a <strong>Home Connect</strong> order is
                                submitted, it cannot be canceled or modified
                                through the app. However, you can contact the
                                SRON support team for assistance if needed.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"12. How do I change the language in the app?"}
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                To change the language in the app, go to the
                                top-right corner of the app, where you can
                                select either <strong>English</strong> or{" "}
                                <strong>Malay </strong>
                                as your preferred language.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"13. Can I transfer my voucher to another user?"}
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                No, vouchers purchased through SRON are
                                non-transferable and are tied to the account
                                used for the purchase.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaFAQ)

DanawaFAQ.propTypes = {
  t: PropTypes.any,
}
