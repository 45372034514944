import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Card,
  CardImg,
  CardTitle,
  Modal,
  Collapse,
} from "reactstrap"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as url from "helpers/url_helper"
import ScrollContainer from "react-indiana-drag-scroll"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import i18n from "i18n"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/ugifts/loginbg.png"
import bgProfile from "assets/images/ugifts/profile_bg.png"
import bgFriend from "assets/images/ugifts/friend_bg.png"
import inviteFriend from "assets/images/ugifts/invite_friend.png"
import iconPoint from "assets/images/ugifts/icons/profile_point.png"
import iconRibbon from "assets/images/ugifts/icon_ribbon.png"
import inviteFriendBg from "assets/images/ugifts/invite_friend_purple_bg.png"

import { Button } from "@material-ui/core"

import emptyLogin from "assets/images/ugifts/icons/empty/empty_login.png"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const languageData = [
  { name: "English", short: "eng" },
  { name: "Chinese", short: "cn" },
  { name: "Japanese", short: "jp" },
]

const packageArray = [
  { name: "All", packageType: "All" },
  { name: "Public Wifi", packageType: "Public" },
  { name: "Home Connect", packageType: "Home" },
  { name: "Corporate Connect", packageType: "Corporate" },
]

const DanawaProductList = props => {
  const query = useQuery()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [uid, setUid] = useState(null)
  const [basketCount, setBasketCount] = useState(null)
  const [isGuest, setIsGuest] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.id)
      console.log(obj)
    } else {
      setIsGuest(true)
    }

    if (localStorage.getItem("basketCount")) {
      setBasketCount(localStorage.getItem("basketCount"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }
  
  const [packageType, setPackageType] = useState("All")
  const [packageData, setPackageData] = useState([])
  const getPackages = async () => {
    // var form = JSON.stringify({
    //   option: "list_package",
    //   type: type,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_package")
    urlencoded.append("type", packageType)
    // urlencoded.append("password", password)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request package", urlencoded)

    const response = await fetch(url.DANAWA_POST_MEMBER, requestOptions)
    const postresponse = await response.json()

    console.log("list_package", postresponse)

    if (response.status == 200 && postresponse.success) {
      setPackageData(postresponse.data)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    getPackages()
  }, [packageType])

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Packages</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              xl={7}
              lg={10}
              className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "white",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content"
                style={{
                  minHeight: "100vh",
                  // paddingTop: "56px",
                  paddingBottom: "108px",
                  // background: "#EBEBEB",
                }}
              >
                <div className="w-100">
                  {/* <div className="profile-white" /> */}
                  {/* <div className="profile-grey" /> */}
                  <div className="d-flex flex-column h-100 px-4">
                    <Row className="mb-3 mt-3 mx-0">
                      {/* <Col xs={"auto"} className="px-0 pe-3">
                        <Link to="/dashboard">
                          <button className="btn btn-outline-light btn-rounded btn-block waves-effect waves-light font-size-24">
                            <i className="bx bx-arrow-back align-middle font-size-24" />
                          </button>
                        </Link>
                      </Col> */}
                      <Col className="align-self-center px-0">
                        <span
                          className="mb-0 font-size-24 text-primary"
                          style={{ fontWeight: 700 }}
                        >
                          {props.t("P")}
                        </span>
                        <span
                          className="mb-0 font-size-24 text-secondary"
                          style={{ fontWeight: 700 }}
                        >
                          {props.t("ACKAGES")}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <ScrollContainer className="scroll-container">
                          <Row className="flex-nowrap">
                            {packageArray &&
                              packageArray.map((packageObject, index) => (
                                <Col
                                  className="pe-0"
                                  key={"package-object-" + index}
                                  xs={"auto"}
                                >
                                  <button
                                    className={
                                      packageType == packageObject.packageType
                                        ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-14 text-uppercase px-3"
                                        : "btn btn-light btn-block waves-effect waves-light w-100 font-size-14 text-uppercase text-muted px-3"
                                    }
                                    style={{
                                      borderRadius: "50px",
                                      whiteSpace: "nowrap",
                                      // aspectRatio: "9/2",
                                    }}
                                    onClick={() => {
                                      setPackageType(packageObject.packageType)
                                    }}
                                  >
                                    <strong>
                                      {props.t(packageObject.name)}
                                    </strong>
                                  </button>
                                </Col>
                              ))}
                          </Row>
                        </ScrollContainer>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {packageData &&
                          packageData.map((packages, index) => (
                            <Row className="mb-2" key={"package-" + index}>
                              <Col>
                                <Link to={"/product-details?id=" + packages.id}>
                                  <button
                                    className="btn btn-outline-light btn-block waves-effect waves-light w-100 font-size-20 p-3 border-radius-12"
                                    style={{ height: "120px" }}
                                  >
                                    <Row>
                                      <Col className="col-auto">
                                        <img
                                          src={
                                            // "https://dummyimage.com/88x88/000/ffffff.png&text=Voucher"
                                            packages.image
                                          }
                                          className="avatar-lg"
                                          alt=""
                                          style={{
                                            borderRadius: "8px",
                                            width: "132px",
                                            height: "88px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </Col>
                                      <Col className="text-dark font-size-16 justify-content-between d-flex flex-column">
                                        <Row>
                                          <Col className="text-start">
                                            {packages.name}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col className="text-end">
                                            {/* {"MYR " + packages.price} */}
                                            {packages.type == "Corporate" &&
                                              "Subject to site survey"}
                                            {packages.type != "Corporate" && "MYR " + packages.price}{" "}
                                            {packages.type == "Home" && props.t("/Month")}
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col className="col-auto">
                                        <i className="bx bx-chevron-right align-middle font-size-30 text-lighter" />
                                      </Col>
                                    </Row>
                                  </button>
                                </Link>
                              </Col>
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaProductList)

DanawaProductList.propTypes = {
  t: PropTypes.any,
}
