import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Card,
  CardImg,
  CardTitle,
  Modal,
} from "reactstrap"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as url from "helpers/url_helper"
import SweetAlert from "react-bootstrap-sweetalert"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

//empty state
// import UgiftEmptyState from "./UgiftEmptyState"
import emptyImage from "assets/images/ugifts/icons/empty/empty_outlet.png"

import dummy1 from "assets/images/danawa/1.png"
import dummy2 from "assets/images/danawa/2.png"

const imageDummy = [dummy1, dummy2]

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ArticleDetails = props => {
  const query = useQuery()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [uid, setUid] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [listType, setListType] = useState("detail_article")
  const [pid, setPid] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [stockLevel, setStockLevel] = useState(0)
  const [itemInBasket, setItemInBasket] = useState(false)
  const [currency, setCurrency] = useState("")
  const [basketCount, setBasketCount] = useState(null)
  const [giftcardId, setGiftcardId] = useState(null)
  const [isGuest, setIsGuest] = useState(false)

  const [language, setLanguage] = useState("eng")

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.member_id)
      console.log(obj)
    } else {
      setIsGuest(true)
    }

    if (query.get("id")) {
      setPid(query.get("id"))
    }

    if (localStorage.getItem("gid")) {
      setGiftcardId(localStorage.getItem("gid"))
    }

    if (localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"))
    }

    if (localStorage.getItem("basketCount")) {
      setBasketCount(localStorage.getItem("basketCount"))
    }

    if (localStorage.getItem("I18N_LANGUAGE")) {
      setLanguage(localStorage.getItem("I18N_LANGUAGE"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    console.log("UgiftZi)$rnK}K%I8PZKey", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (token != null) {
      getList()
      // if (!isGuest) getBasketData()
      // if (giftcardId) getGiftList()
      console.log("use effect")
    }
  }, [token, listType])

  const getList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", listType)
    urlencoded.append("article_id", pid)
    if (!isGuest) urlencoded.append("member_id", uid)
    // urlencoded.append("option", "get_api_token")
    // urlencoded.append("source", "web")
    // urlencoded.append("api_key", "UgiftZi)$rnK}K%I8PZKey")

    // var form = JSON.stringify({
    //   option: "get_package_details",
    //   id: pid,
    // })
    
    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   // "Content-Type": "application/json",
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("Request article details", urlencoded.toString())

    const response = await fetch(
      url.DANAWA_POST_FRONTEND,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // console.log(typeof postresponse.table.applicable_outlets)
      setTableData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  // useEffect(() => {
  //   if (tableData) {
  //     setStockLevel(tableData.stock)
  //     if (tableData.stock < quantity) {
  //       setQuantity(tableData.stock)
  //     }
  //   }
  // }, [tableData])

  const [basketData, setBasketData] = useState(null)
  const getBasketData = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_basket")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request basket", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setBasketData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  // useEffect(() => {
  //   if (basketData) {
  //     var productList = basketData.products.filter(product => {
  //       return product.product_id == pid
  //     })
  //     if (productList.length > 0) {
  //       setQuantity(parseInt(productList[0].quantity))
  //       setItemInBasket(true)
  //     }

  //     var calculate = 0
  //     basketData.products.forEach(product => {
  //       calculate += parseInt(product.quantity)
  //     })
  //     localStorage.setItem("basketCount", calculate)
  //     setBasketCount(calculate)
  //   }
  // }, [basketData])

  const updateBasket = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "product_to_basket")
    urlencoded.append("member_id", uid)
    urlencoded.append("product_id", pid)
    urlencoded.append("quantity", quantity)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request basket", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setBasketData(postresponse.table)
      if (giftcardId) {
        updateGiftCardId()
      }
      getBasketData()
      history.push("/basket")

      // setsuccess_dlg(true)
      // setdynamic_title("Cart Updated")
      // setdynamic_description(postresponse.message)
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Cart Update Failed"))
      setdynamic_description(postresponse.message)
    }
  }

  const [giftData, setGiftData] = useState(null)
  const getGiftList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_giftcard")
    // urlencoded.append("order_by", sortType)
    urlencoded.append("giftcard_id", giftcardId)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request giftcard product", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_GIFT, requestOptions)
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setGiftData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  const updateGiftCardId = async () => {
    if (basketData.giftcard_id != "0") return

    if (!localStorage.getItem("gid")) return

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "giftcard_to_basket")
    urlencoded.append("member_id", uid)
    urlencoded.append("giftcard_id", giftcardId)
    if (basketData.giftcard_id != giftcardId)
      urlencoded.append("giftcard_edit_parameter", giftData.edit_parameter)
    else
      urlencoded.append(
        "giftcard_edit_parameter",
        basketData.giftcard_edit_parameter
      )

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request basket", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/basket")
      // history.push("/tables-applicationtable")
      // setBasketData(postresponse.table)
      // setsuccess_dlg(true)
      // setdynamic_title("Cart Updated")
      // setdynamic_description(postresponse.message)
    } else {
      // seterror_alert(true)
      // setdynamic_title("Cart Update Failed")
      // setdynamic_description(postresponse.message)
    }
  }

  function increaseQuantity() {
    if (quantity < stockLevel) setQuantity(quantity + 1)
  }

  function decreaseQuantity() {
    if (quantity > 1) setQuantity(quantity - 1)
  }

  const [modal_tnc, setmodal_tnc] = useState(false)
  const [modal_redeem, setmodal_redeem] = useState(false)
  const [modal_available, setmodal_available] = useState(false)

  function tog_tnc() {
    setmodal_tnc(!modal_tnc)
    removeBodyCss()
  }

  function tog_howToRedeem() {
    setmodal_redeem(!modal_redeem)
    removeBodyCss()
  }

  function tog_available() {
    setmodal_available(!modal_available)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const [success_dlg, setsuccess_dlg] = useState(false)
  const [success_dlg_noaction, setsuccess_dlg_noaction] = useState(false)
  const [error_alert, seterror_alert] = useState(false)
  const [redirect_alert, setredirect_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const multiLanguageHandler = data => {
    switch (language) {
      case "eng":
        return data.en
      case "cn":
        return data.cn ? data.cn : data.en
      case "jp":
        return data.jp ? data.jp : data.en
      default:
        return data.en
    }
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Articles</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            {success_dlg_noaction ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg_noaction(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  history.push("/basket")
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {error_alert ? (
              <SweetAlert
                title={dynamic_title}
                danger
                onConfirm={() => {
                  seterror_alert(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {redirect_alert ? (
              <SweetAlert
                title={dynamic_title}
                onCancel={() => {
                  setredirect_alert(false)
                }}
                customButtons={<React.Fragment></React.Fragment>}
              >
                <React.Fragment>
                  <Row className="px-3 py-2">
                    <button
                      className="btn btn-primary waves-effect waves-light font-size-15 me-2"
                      onClick={() => {
                        setredirect_alert(false)
                        history.push("/login")
                      }}
                    >
                      {props.t("Login")}
                    </button>
                  </Row>
                  <Row className="px-3 py-2">
                    <button
                      className="btn btn-light waves-effect waves-light font-size-15"
                      onClick={() => {
                        setredirect_alert(false)
                      }}
                    >
                      {props.t("Cancel")}
                    </button>
                  </Row>
                </React.Fragment>
              </SweetAlert>
            ) : null}
            <Col
              xl="7"
              lg="10"
              className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "white",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content"
                style={{
                  minHeight: "100vh",
                  // backgroundColor: "#F5F5F5",
                  // paddingTop: "56px",
                  paddingBottom: "130px",
                }}
              >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <Row className="mb-3 mt-3 mx-0">
                      <Col xs={"auto"} className="px-0 px-3">
                        {/* <Link to="/dashboard"> */}
                          <button className="btn btn-outline-light btn-rounded btn-block waves-effect waves-light font-size-24" onClick={() =>{history.goBack()}}>
                            <i className="bx bx-arrow-back align-middle font-size-24" />
                          </button>
                        {/* </Link> */}
                      </Col>
                      <Col className="align-self-center">
                        <span
                          className="mb-0 font-size-24 text-primary"
                          style={{ fontWeight: 700 }}
                        >
                          {props.t("A")}
                        </span>
                        <span
                          className="mb-0 font-size-24 text-secondary"
                          style={{ fontWeight: 700 }}
                        >
                          {props.t("RTICLES")}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Carousel
                        infiniteLoop
                        autoPlay
                        swipeable
                        emulateTouch
                        interval={5000}
                        showThumbs={false}
                        className="slider_css"
                        dir="rtl"
                      >
                        {/* {tableData.image.map((banner, index) => (
                          <div>
                            <div className="item">
                              <Card
                                className="text-center"
                                // style={{ borderRadius: "16px" }}
                              >
                                <CardImg
                                  top
                                  className="img-fluid"
                                  style={{
                                    height: "280px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                  }}
                                  src={banner.image}
                                  alt=""
                                />
                              </Card>
                            </div>
                          </div>
                        ))} */}
                        {/* {tableData &&
                          tableData.product_images.map((data, index) => (
                            <div key={`data-${index}`}>
                              <div className="item">
                                <Card
                                  className="text-center shadow-none"
                                  style={{ borderRadius: "0px" }}
                                >
                                  <CardImg
                                    top
                                    className="img-fluid"
                                    style={{
                                      // height: "280px",
                                      objectFit: "cover",
                                      // borderRadius: "8px",
                                      aspectRatio: "3/2",
                                    }}
                                    src={data.image}
                                    alt=""
                                  />
                                </Card>
                              </div>
                            </div>
                          ))} */}
                        {/* {imageDummy &&
                          imageDummy.map((data, index) => (
                            <div key={`data-${index}`}>
                              <div className="item">
                                <Card
                                  className="text-center shadow-none"
                                  style={{ borderRadius: "0px" }}
                                >
                                  <CardImg
                                    top
                                    className="img-fluid"
                                    style={{
                                      // height: "280px",
                                      objectFit: "cover",
                                      // borderRadius: "8px",
                                      aspectRatio: "3/2",
                                    }}
                                    src={data}
                                    alt=""
                                  />
                                </Card>
                              </div>
                            </div>
                          ))} */}
                        {tableData && <div>
                          <div className="item">
                            <Card
                              className="text-center shadow-none"
                              style={{ borderRadius: "0px" }}
                            >
                              <CardImg
                                top
                                className="img-fluid"
                                style={{
                                  // height: "280px",
                                  objectFit: "cover",
                                  // borderRadius: "8px",
                                  aspectRatio: "3/2",
                                }}
                                src={tableData.image}
                                alt=""
                              />
                            </Card>
                          </div>
                        </div>}
                      </Carousel>
                    </Row>
                    <Row>
                      <Col>
                        <Card
                          className="p-3 shadow-none"
                          style={{
                            // borderRadius: "12px",
                            // width: "160px",
                            // height: "196px",
                            backgroundColor: "white",
                          }}
                        >
                          <Row>
                            <Col>
                              <CardTitle className="font-size-20">
                                {tableData && tableData.title}
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="auto">
                              {tableData && (
                                <span
                                  className="font-size-14"
                                  dangerouslySetInnerHTML={{
                                    __html: tableData.content,
                                  }}
                                ></span>
                              )}
                            </Col>
                          </Row>
                          {/* {tableData &&
                            tableData.campaign_info &&
                            tableData.campaign_info.discount_mode && (
                              <hr
                                style={{
                                  color: "#EAEAEA",
                                  backgroundColor: "#EAEAEA",
                                  height: "2px",
                                }}
                              />
                            )}
                          {tableData &&
                            tableData.campaign_info &&
                            tableData.campaign_info.discount_mode && (
                              <Row
                                onClick={() => {
                                  history.push(
                                    "/campaign?id=" +
                                      tableData.campaign_info.campaign_id
                                  )
                                }}
                              >
                                <Col>
                                  <i className="bx bxs-discount align-middle font-size-20 text-danger me-2" />
                                  <span className="font-size-15 text-dark align-middle">
                                    {tableData.campaign_info &&
                                      tableData.campaign_info.discount_message}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <Row>
                                    <Col>
                                      <i
                                        className="bx bx-cookie align-middle font-size-20"
                                        style={{ color: "#A5A5A5" }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )} */}
                          {/* <hr
                            style={{
                              color: "#EAEAEA",
                              backgroundColor: "#EAEAEA",
                              height: "2px",
                            }}
                          /> */}
                          {/* <Row
                            onClick={() => {
                              tog_howToRedeem()
                            }}
                          >
                            <Col>
                              <span className="font-size-15 text-dark">
                                {props.t("How to redeem")}
                              </span>
                            </Col>
                            <Col xs="auto">
                              <Row>
                                <Col>
                                  <i
                                    className="bx bx-cookie align-middle font-size-20"
                                    style={{ color: "#A5A5A5" }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row> */}
                          {/* <hr
                            style={{
                              color: "#EAEAEA",
                              backgroundColor: "#EAEAEA",
                              height: "2px",
                            }}
                          /> */}
                          {/* <Row
                            onClick={() => {
                              tog_available()
                            }}
                          >
                            <Col>
                              <span className="font-size-15 text-dark">
                                {props.t("Available outlets")}
                              </span>
                            </Col>
                            <Col xs="auto">
                              <Row>
                                <Col>
                                  <i
                                    className="bx bx-cookie align-middle font-size-20"
                                    style={{ color: "#A5A5A5" }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row> */}
                          {/* <hr
                            style={{
                              color: "#EAEAEA",
                              backgroundColor: "#EAEAEA",
                              height: "2px",
                            }}
                          />
                          <Row
                            onClick={() => {
                              tog_tnc()
                            }}
                          >
                            <Col>
                              <span className="font-size-15 text-dark">
                                {props.t("Terms & Conditions")}
                              </span>
                            </Col>
                            <Col xs="auto">
                              <Row>
                                <Col>
                                  <i
                                    className="bx bx-cookie align-middle font-size-20"
                                    style={{ color: "#A5A5A5" }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <hr
                            style={{
                              color: "#EAEAEA",
                              backgroundColor: "#EAEAEA",
                              height: "2px",
                            }}
                          /> */}
                        </Card>
                      </Col>
                    </Row>

                    {/* <Row
                      className="m-0 w-100"
                      style={{
                        height: "130px",
                        // backgroundColor: "#ffffff",
                        position: "fixed",
                        bottom: 0,
                        zIndex: 1030,
                        maxWidth: "480px",
                        boxShadow: "0 -0.75rem 1.5rem rgba(18, 38, 63, 0.03)",
                      }}
                    >
                      <Col className="h-100 p-0 bg-secondary">
                        <Row className="mt-3">
                          <Col className="align-self-center text-end">
                            <span
                              className="font-size-20 h-100 pe-3 text-white"
                              style={{ fontWeight: 700 }}
                            >
                              {tableData && "MYR " + tableData.price}
                            </span>
                          </Col>
                        </Row>
                        <Card body className="mb-0 p-3 bg-transparent">
                          <button
                            className="btn btn-primary waves-effect waves-light font-size-18"
                            style={{ fontWeight: 700 }}
                            onClick={() => {
                              if (!isGuest) updateBasket()
                              else {
                                setredirect_alert(true)
                                setdynamic_title(
                                  props.t("Welcome, please login to continue")
                                )
                              }
                            }}
                            // disabled={stockLevel == 0}
                          >
                            {stockLevel != 0
                              ? itemInBasket
                                ? props.t("Update Cart")
                                : props.t("Add to Cart")
                              : props.t("Out of stock")}
                          </button>
                        </Card>
                      </Col>
                    </Row> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal_tnc}
        toggle={() => {
          tog_tnc()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Terms & Conditions")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_tnc(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/* <div
          className="modal-body"
          dangerouslySetInnerHTML={
            tableData && {
              __html: multiLanguageHandler(tableData.multiple_languages)
                .terms_and_conditions,
            }
          }
        ></div> */}
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_tnc()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Close")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
      <Modal
        isOpen={modal_redeem}
        toggle={() => {
          tog_howToRedeem()
        }}
      >
        <div className="modal-header">
          <span className="modal-title mt-0" id="myModalLabel">
            {props.t("How to redeem")}
          </span>
          <button
            type="button"
            onClick={() => {
              setmodal_tnc(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/* <div
          className="modal-body"
          dangerouslySetInnerHTML={
            tableData && {
              __html: multiLanguageHandler(tableData.multiple_languages)
                .redeem_howto,
            }
          }
        ></div> */}
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_howToRedeem()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Close")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
      <Modal
        isOpen={modal_available}
        toggle={() => {
          tog_available()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Available Outlet")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_available(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {tableData &&
            tableData.applicable_outlets &&
            // typeof tableData.applicable_outlets != "object" &&
            tableData.applicable_outlets.map((outlet, index) => (
              <div>
                <Row className="mb-2">
                  <Col>
                    <strong>
                      <span className="font-size-15">{outlet.outlet_name}</span>
                    </strong>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <span className="font-size-15">{outlet.address}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <span className="font-size-15">{outlet.outlet_telno}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="font-size-15">{outlet.email}</span>
                  </Col>
                </Row>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA",
                    height: "2px",
                  }}
                />
              </div>
            ))}
          {/* {tableData &&
            tableData.applicable_outlets &&
            tableData.applicable_outlets.length == 0 && (
              <UgiftEmptyState
                image={emptyImage}
                title={props.t("No outlets available")}
                link={null}
                linkText=""
              />
            )} */}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_available()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Close")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(ArticleDetails)

ArticleDetails.propTypes = {
  t: PropTypes.any,
}
