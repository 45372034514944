import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { Nav, NavItem } from "reactstrap"
import { NavLink } from "react-router-dom"
import classname from "classnames"
import * as url from "helpers/url_helper"

//i18n
import { withTranslation } from "react-i18next"
import i18n from "i18n"

import { connect } from "react-redux"
import danawaLogo from "assets/images/danawa/danawa-logo.jpg"

const tabs = [
  {
    route: "/dashboard",
    icon: "bxs-home",
    label: "Home",
  },
  {
    route: "/product-list",
    icon: "bx-zoom-in",
    label: "Explore",
  },
  {
    route: "/faq",
    icon: "bx-heart",
    label: "FAQ",
  },
  {
    route: "/profile",
    icon: "bx-user",
    label: "Profile",
  },
]

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [ui, setui] = useState(false)
  const [app, setapp] = useState(false)
  const [email, setemail] = useState(false)
  const [ecommerce, setecommerce] = useState(false)
  const [crypto, setcrypto] = useState(false)
  const [project, setproject] = useState(false)
  const [task, settask] = useState(false)
  const [contact, setcontact] = useState(false)
  const [blog, setBlog] = useState(false)
  const [component, setcomponent] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)
  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)
  const [extra, setextra] = useState(false)
  const [invoice, setinvoice] = useState(false)
  const [auth, setauth] = useState(false)
  const [utility, setutility] = useState(false)

  const [footerHidden, setFooterHidden] = useState(false)

  useEffect(() => {
    hideFooter()
    if (!footerHidden) {
      var matchingMenuItem = null
      var ul = document.getElementById("navigation")
      var items = ul.getElementsByTagName("a")
      for (var i = 0; i < items.length; ++i) {
        if ("/web" + props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    // localStorage.setItem("I18N_LANGUAGE", "eng")
    // i18n.changeLanguage("eng")
  }, [])

  function hideFooter() {
    // console.log(props.location.pathname)
    switch (props.location.pathname) {
      // Danawa
      case "/dashboard":
      // case "/products":
      case "/gift-card":
      // case "/gift-card-product":
      // case "/product-details":
      // case "/view-vouchers":
      // case "/voucher-details":
      // case "/product-search":
      // case "/invite-friend":
      // case "/friends":
      // case "/basket":
      // case "/profile-edit":
      // case "/order-topay":
      // case "/order-mypurchases":
      // case "/order-received":
      // case "/order-details":
      // case "/payment-status":
      // case "/campaign":
      case "/home":
      // case "/categories":
      case "/vouchers":
      case "/notification":
      case "/profile":
      case "/product-list": 
      case "/faq":
      // case "/profile":
        setFooterHidden(false)
        break
      default:
        setFooterHidden(true)
    }

    return false
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      {!footerHidden && (
        <nav
          className="navbar fixed-bottom navbar-light p-0"
          id="navigation"
          role="navigation"
        >
          <Nav className="w-100 navbar-btm">
            <div
              className=" d-flex flex-row justify-content-around w-100"
              style={{
                // maxWidth: "480px",
                backgroundColor: "#CD3D33",
              }}
            >
              {tabs.map((tab, index) => (
                <li
                  className="nav-item"
                  key={`tab-${index}`}
                  style={{ width: "24%" }}
                >
                  <Link
                    className="nav-link row d-flex flex-column justify-content-center align-items-center px-1 g-0"
                    to={tab.route}
                  >
                    <i
                      className={"bx font-size-20 text-center " + tab.icon}
                      // style={{ color: "#8D9BB1" }}
                    ></i>
                    <div
                      className="font-size-12 text-center"
                      // style={{ color: "#8D9BB1" }}
                    >
                      {props.t(tab.label)}
                    </div>
                  </Link>
                </li>
              ))}
            </div>
            <div
              className=" d-flex flex-row justify-content-center align-items-center font-size-12 w-100 py-2"
              style={{
                // maxWidth: "480px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <strong>Powered By</strong>
              <img
                src={danawaLogo}
                alt=""
                className="ms-2"
                style={{
                  // position: "absolute",
                  objectFit: "contain",
                  maxHeight: "20px",
                }}
              />
            </div>
          </Nav>
        </nav>
      )}
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
