import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Card,
  CardImg,
  CardTitle,
  Modal,
  Collapse,
} from "reactstrap"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as url from "helpers/url_helper"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import i18n from "i18n"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/ugifts/loginbg.png"
import bgProfile from "assets/images/ugifts/profile_bg.png"
import bgFriend from "assets/images/ugifts/friend_bg.png"
import inviteFriend from "assets/images/ugifts/invite_friend.png"
import iconPoint from "assets/images/ugifts/icons/profile_point.png"
import iconRibbon from "assets/images/ugifts/icon_ribbon.png"
import inviteFriendBg from "assets/images/ugifts/invite_friend_purple_bg.png"

import { Button } from "@material-ui/core"

import emptyLogin from "assets/images/ugifts/icons/empty/empty_login.png"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const languageData = [
  { name: "English", short: "eng" },
  { name: "Chinese", short: "cn" },
  { name: "Japanese", short: "jp" },
]

const DanawaProfile = props => {
  const query = useQuery()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [uid, setUid] = useState(null)
  const [basketCount, setBasketCount] = useState(null)
  const [isGuest, setIsGuest] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.id)
      console.log(obj)
    } else {
      setIsGuest(true)
    }

    if (localStorage.getItem("basketCount")) {
      setBasketCount(localStorage.getItem("basketCount"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (uid != null) {
      // if (!isGuest) {
        getUserProfile()
      //   getFriendList()
      //   getOrderList()
      //   getBasketData()
      //   getUdollar()
      // }
      console.log("use effect")
    }
  }, [uid])

  const [userData, setUserData] = useState(null)
  const getUserProfile = async () => {
    // var form = JSON.stringify({
    //   option: "detail_profile",
    //   id: uid,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_profile")
    urlencoded.append("id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   // "Content-Type": "application/json",
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("Request user profile", urlencoded)

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setUserData(postresponse.data)
    } else {
      // seterror_alert(true)
    }
  }

  const getOrderList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_order")
    urlencoded.append("member_id", uid)
    urlencoded.append("filter_by", "purchased")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request order list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_ORDER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  const [friendData, setFriendData] = useState(null)
  const getFriendList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_friend")
    urlencoded.append("order_by", "asc")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request category", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setFriendData(postresponse)
    } else {
      // seterror_alert(true)
    }
  }

  const getBasketData = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_basket")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request basket", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      var calculate = 0
      postresponse.table.products.forEach(product => {
        calculate += parseInt(product.quantity)
      })
      localStorage.setItem("basketCount", calculate)
      setBasketCount(calculate)
    } else {
    }
  }

  const getUdollar = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_udollar_giftcards")
    urlencoded.append("member_id", 52)
    urlencoded.append("currency_code", "MYR")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request udollar list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  function logout() {
    localStorage.removeItem("authUser")
    // localStorage.removeItem("basketCount")
    // localStorage.removeItem("fbToken")
    history.push("/login")
  }

  const [languageSelected, setlanguageSelected] = useState(false)
  const [modal_language, setmodal_language] = useState(false)
  function tog_language() {
    setmodal_language(!modal_language)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Profile</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              // lg={10}
              // className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "white",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                // className="auth-full-page-content"
                // className="flex"
                style={{
                  display: "flex",
                  minHeight: "100vh",
                  // paddingTop: "56px",
                  paddingBottom: "105px",
                  background: "#EBEBEB",
                }}
              >
                <div
                  className="w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="profile-white" />
                  {/* <div className="curved" /> */}
                  <div className="d-flex flex-column h-100 px-4">
                    <Row className="justify-content-center">
                      <Col lg={"10"}>
                        <Row className="mb-3 mt-3 mx-0">
                          {/* <Col xs={"auto"} className="px-0 pe-3">
                        <Link to="/dashboard">
                          <button className="btn btn-outline-light btn-rounded btn-block waves-effect waves-light font-size-24">
                            <i className="bx bx-arrow-back align-middle font-size-24" />
                          </button>
                        </Link>
                      </Col> */}
                          <Col className="align-self-center">
                            <span
                              className="mb-0 font-size-24 text-primary"
                              style={{ fontWeight: 700 }}
                            >
                              {props.t("MY")}
                            </span>
                            <span
                              className="mb-0 font-size-24 text-secondary"
                              style={{ fontWeight: 700 }}
                            >
                              {props.t("PROFILE")}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card
                              className="p-4 mb-0 shadow-none bg-primary"
                              style={{
                                borderRadius: "14px",
                                // borderBottomLeftRadius: "14px",
                                // borderBottomRightRadius: "14px",
                                // width: "160px",
                                // minHeight: "192px",
                                // backgroundColor: "white",
                                // backgroundImage: `url(${bgProfile})`,
                                // backgroundSize: "cover",
                              }}
                            >
                              <Row>
                                <Col className="col-auto">
                                  <div className="avatar-lg">
                                    <span
                                      className={
                                        "avatar-title rounded-circle bg-white text-primary font-size-24 text-uppercase"
                                      }
                                    >
                                      {userData &&
                                        userData.first_name.charAt(0)}
                                    </span>
                                  </div>
                                </Col>
                                <Col className="align-self-center">
                                  <p className="font-size-20 text-white mb-0">
                                    {"Welcome"}
                                  </p>
                                  <span
                                    className={
                                      "font-size-20 text-center text-white"
                                    }
                                    // style={{ fontWeight: 600 }}
                                  >
                                    {userData && userData.first_name}
                                  </span>
                                </Col>
                              </Row>

                              {/* {userData &&
                            (userData.profile_picture ? (
                              <Link to="/profile-edit">
                                <div className="avatar-lg mx-auto">
                                  <img
                                    src={userData.profile_picture}
                                    className="rounded-circle avatar-lg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            ) : (
                              <Link to="/profile-edit">
                                <div className="avatar-lg mx-auto">
                                  <span
                                    className={
                                      "avatar-title rounded-circle bg-primary text-white font-size-24"
                                    }
                                  >
                                    {userData.name
                                      ? userData.name.charAt(0)
                                      : userData.phone_number.charAt(0)}
                                  </span>
                                </div>
                              </Link>
                            ))} */}
                              {/* {userData && (
                            <span
                              className={"font-size-20 text-center text-white"}
                              style={{ fontWeight: 700 }}
                            >
                              {userData.name
                                ? userData.name
                                : "+" + userData.phone_number}
                            </span>
                          )} */}
                              {/* <span
                            className={"font-size-14 text-center text-white"}
                            style={{ fontWeight: 400 }}
                          >
                            <Link to="/profile-edit" className={"text-white"}>
                              {"Edit Profile"}
                            </Link>
                          </span> */}
                              {/* {userData && (
                            <Row className="mt-3">
                              <Col xs="auto" className="mx-auto">
                                <button
                                  className={
                                    "btn btn-rounded bg-white font-size-16 text-center"
                                  }
                                  style={{ fontWeight: 700, color: "#F49913" }}
                                >
                                  <img
                                    src={iconPoint}
                                    className="rounded-circle avatar-xs me-2"
                                    alt=""
                                    style={{
                                      maxWidth: "24px",
                                      maxHeight: "24px",
                                    }}
                                  />
                                  {userData.points + " Points"}
                                </button>
                              </Col>
                            </Row>
                          )} */}
                            </Card>
                            <Row className="mt-4 mb-2">
                              <Col>
                                <span className="font-size-22 fw-bold text-uppercase">
                                  {"My Account"}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>
                                <Link to="/profile-edit">
                                  <button
                                    className="btn btn-light btn-block waves-effect waves-light w-100 font-size-20 p-4 border-radius-12"
                                    style={{ height: "100px" }}
                                  >
                                    <Row>
                                      <Col className="col-auto">
                                        <i className="bx bx-user align-middle font-size-30 text-darker" />
                                      </Col>
                                      <Col className="text-start text-dark">
                                        {"Edit Profile"}
                                      </Col>
                                      <Col className="col-auto">
                                        <i className="bx bx-chevron-right align-middle font-size-30 text-lighter" />
                                      </Col>
                                    </Row>
                                  </button>
                                </Link>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>
                                <Link to="/change-password">
                                  <button
                                    className="btn btn-light btn-block waves-effect waves-light w-100 font-size-20 p-4 border-radius-12"
                                    style={{ height: "100px" }}
                                  >
                                    <Row>
                                      <Col className="col-auto">
                                        <i className="bx bx-lock-alt align-middle font-size-30 text-darker" />
                                      </Col>
                                      <Col className="text-start text-dark">
                                        {"Change Password"}
                                      </Col>
                                      <Col className="col-auto">
                                        <i className="bx bx-chevron-right align-middle font-size-30 text-lighter" />
                                      </Col>
                                    </Row>
                                  </button>
                                </Link>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Col>
                                <button
                                  className="btn btn-light btn-block waves-effect waves-light w-100 font-size-20 p-4 border-radius-12"
                                  style={{ height: "100px" }}
                                >
                                  <Row>
                                    <Col className="col-auto">
                                      <i className="bx bx-cog align-middle font-size-30 text-darker" />
                                    </Col>
                                    <Col className="text-start text-dark">
                                      {"Settings"}
                                    </Col>
                                    <Col className="col-auto">
                                      <i className="bx bx-chevron-right align-middle font-size-30 text-lighter" />
                                    </Col>
                                  </Row>
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-auto">
                    <Row className="p-4 bg-secondary g-0 justify-content-center">
                      <Col lg={"10"}>
                        {/* <Link to="/login"> */}
                        <button
                          className={
                            "btn btn-primary btn-block waves-effect waves-light w-100 font-size-18 py-3 text-uppercase"
                          }
                          // type="submit"
                          style={{ borderRadius: "12px" }}
                          onClick={() => {
                            logout()
                          }}
                        >
                          <strong>{props.t("Sign Out")}</strong>
                        </button>
                        {/* </Link> */}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal_language}
        toggle={() => {
          tog_language()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Language")}
          </h5>
          <button
            type="button"
            onClick={() => {
              tog_language()
              setlanguageSelected(localStorage.getItem("I18N_LANGUAGE"))
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {languageData.map((data, index) => (
            <div key={"lang-" + index}>
              <Row
                className="mb-2"
                onClick={() => {
                  setlanguageSelected(data.short)
                  // tog_language()
                }}
              >
                <Col>
                  <span className="font-size-15">{data.name}</span>
                </Col>
                <Col xs="auto">
                  <span className="font-size-15">
                    {languageSelected == data.short && (
                      <i className="bx bx-comment-check align-middle font-size-20 text-dark" />
                    )}
                  </span>
                </Col>
              </Row>
              <hr
                style={{
                  color: "#EAEAEA",
                  backgroundColor: "#EAEAEA",
                  height: "2px",
                }}
              />
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              i18n.changeLanguage(languageSelected)
              localStorage.setItem("I18N_LANGUAGE", languageSelected)
              tog_language()
            }}
            className="btn btn-primary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Apply")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaProfile)

DanawaProfile.propTypes = {
  t: PropTypes.any,
}
