import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Card,
  CardImg,
  CardTitle,
  Modal,
  Collapse,
} from "reactstrap"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as url from "helpers/url_helper"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import i18n from "i18n"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/ugifts/loginbg.png"
import bgProfile from "assets/images/ugifts/profile_bg.png"
import bgFriend from "assets/images/ugifts/friend_bg.png"
import inviteFriend from "assets/images/ugifts/invite_friend.png"
import iconPoint from "assets/images/ugifts/icons/profile_point.png"
import iconRibbon from "assets/images/ugifts/icon_ribbon.png"
import inviteFriendBg from "assets/images/ugifts/invite_friend_purple_bg.png"

import { Button } from "@material-ui/core"

import emptyLogin from "assets/images/ugifts/icons/empty/empty_login.png"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const languageData = [
  { name: "English", short: "eng" },
  { name: "Chinese", short: "cn" },
  { name: "Japanese", short: "jp" },
]

const DanawaPrivacyPolicy = props => {
  const query = useQuery()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [uid, setUid] = useState(null)
  const [basketCount, setBasketCount] = useState(null)
  const [isGuest, setIsGuest] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.id)
      console.log(obj)
    } else {
      setIsGuest(true)
    }

    if (localStorage.getItem("basketCount")) {
      setBasketCount(localStorage.getItem("basketCount"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (uid != null) {
      // if (!isGuest) {
        getUserProfile()
      //   getFriendList()
      //   getOrderList()
      //   getBasketData()
      //   getUdollar()
      // }
      console.log("use effect")
    }
  }, [uid])

  const [userData, setUserData] = useState(null)
  const getUserProfile = async () => {
    // var form = JSON.stringify({
    //   option: "detail_profile",
    //   id: uid,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_profile")
    urlencoded.append("id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   // "Content-Type": "application/json",
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("Request user profile", urlencoded)

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setUserData(postresponse.data)
    } else {
      // seterror_alert(true)
    }
  }

  const getOrderList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_order")
    urlencoded.append("member_id", uid)
    urlencoded.append("filter_by", "purchased")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request order list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_ORDER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  const [friendData, setFriendData] = useState(null)
  const getFriendList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_friend")
    urlencoded.append("order_by", "asc")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request category", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setFriendData(postresponse)
    } else {
      // seterror_alert(true)
    }
  }

  const getBasketData = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_basket")
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request basket", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      var calculate = 0
      postresponse.table.products.forEach(product => {
        calculate += parseInt(product.quantity)
      })
      localStorage.setItem("basketCount", calculate)
      setBasketCount(calculate)
    } else {
    }
  }

  const getUdollar = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_udollar_giftcards")
    urlencoded.append("member_id", 52)
    urlencoded.append("currency_code", "MYR")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request udollar list", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_BASKET,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      // setUserData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  function logout() {
    localStorage.removeItem("authUser")
    // localStorage.removeItem("basketCount")
    // localStorage.removeItem("fbToken")
    history.push("/login")
  }

  const [languageSelected, setlanguageSelected] = useState(false)
  const [modal_language, setmodal_language] = useState(false)
  function tog_language() {
    setmodal_language(!modal_language)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Privacy Policy</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              // lg={10}
              // className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "white",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                // className="auth-full-page-content"
                // className="flex"
                style={{
                  display: "flex",
                  minHeight: "100vh",
                  // paddingTop: "56px",
                  paddingBottom: "105px",
                  // background: "#EBEBEB",
                }}
              >
                <div
                  className="w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    lineHeight: 1.8,
                  }}
                >
                  <div className="d-flex flex-column h-100 p-3">
                    <Row className="justify-content-center">
                      <Col xs="10">
                        <Row>
                          <Col>
                            <span className="font-size-30 font-weight-semibold">
                              {"Privacy Policy for SRON"}
                            </span>
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"1. Introduction"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              At SRON, powered by{" "}
                              <strong>Danawa Resources Sdn Bhd</strong>, we are
                              committed to protecting your privacy. This Privacy
                              Policy explains how we collect, use, and protect
                              your personal data when you use SRON. By using
                              SRON, you consent to the collection and use of
                              your information as described in this policy. We
                              are compliant with the{" "}
                              <strong>
                                Personal Data Protection Act 2010 (PDPA)
                              </strong>{" "}
                              of Malaysia.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"2. Information We Collect"}
                            </span>
                            <br />
                            <ul className="font-size-16">
                              <li>
                                <strong>
                                  Personal Identification Information:
                                </strong>{" "}
                                Name, email address, and other details required
                                for account creation.
                              </li>
                              <li>
                                <strong>Payment Information:</strong> Payment
                                details, including credit/debit card numbers and
                                transaction history, for processing purchases.
                              </li>
                              <li>
                                <strong>Usage Data:</strong> Information about
                                how you use the app, such as device information,
                                log data, and interaction with the app.
                              </li>
                              <li>
                                <strong>Location Data:</strong> We may collect
                                location data to offer services in specific
                                regions, such as rural or urban areas in
                                Sarawak.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"3. How We Use Your Information"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              We use your personal information to:
                            </span>
                            <ul className="font-size-16">
                              <li>
                                Facilitate account creation and enable access to
                                SRON’s features.
                              </li>
                              <li>
                                Process payments for subscriptions and internet
                                service products.
                              </li>
                              <li>
                                Provide customer support and resolve issues.
                              </li>
                              <li>
                                Improve and personalize your app experience.
                              </li>
                              <li>
                                Send you updates or promotional information, if
                                you have opted in.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"4. Sharing Your Information"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              We will not sell, rent, or lease your personal
                              information. However, we may share your data with:{" "}
                            </span>
                            <ul className="font-size-16">
                              <li>
                                <strong>Payment Processors:</strong> To process
                                payments via <strong>FPX, Spay Global</strong>,
                                or other payment services.
                              </li>
                              <li>
                                <strong>Legal Compliance:</strong> To comply
                                with laws or protect our legal rights.
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"5. Data Retention"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              We retain your data only as long as necessary to
                              fulfill the purposes for which it was collected.
                              You can request the deletion of your data at any
                              time by contacting us.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"6. Security of Your Information"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              We use industry-standard security measures to
                              protect your personal information. However, no
                              method of data transmission is 100% secure, and we
                              cannot guarantee absolute security.{" "}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"7. Your Rights"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              Under PDPA, you have the right to:
                            </span>
                            <ul className="font-size-16 mb-0">
                              <li>Access and update your personal data.</li>
                              <li>Request deletion of your data.</li>
                              <li>
                                Withdraw consent for marketing communications.
                              </li>
                            </ul>
                            <span className="font-size-16">
                              To exercise your rights, contact us at
                              support@danawa.com.my.{" "}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"8. Cookies and Tracking Technologies"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              We use cookies to enhance your app experience. You
                              can manage cookie preferences through your browser
                              settings.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"9. Children’s Privacy"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              SRON is not intended for children under the age of{" "}
                              <strong>18</strong>. We do not knowingly collect
                              personal data from children. If we discover that a
                              child under 18 has provided personal information,
                              we will delete it immediately.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"10. Changes to Privacy Policy"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              We may update this Privacy Policy from time to
                              time. Changes will be posted on the app or
                              website. Please review periodically.
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-16 font-weight-semibold">
                              {"11. Contact Us"}
                            </span>
                            <br />
                            <span className="font-size-16">
                              For any privacy-related inquiries, contact us at{" "}
                              <strong>support@danawa.com.my</strong> or{" "}
                              <strong>1300-88-22-30</strong>.
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaPrivacyPolicy)

DanawaPrivacyPolicy.propTypes = {
  t: PropTypes.any,
}
