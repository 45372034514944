import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Modal,
} from "reactstrap"
import * as url from "helpers/url_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { AvForm, AvField } from "availity-reactstrap-validation"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/danawa/bg_sign_up.jpg"

import { Button } from "@material-ui/core"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const DanawaLogin = props => {
  const history = useHistory()
  const query = useQuery()

  const [pwVisibility, setpwVisibility] = useState(true)

  const togglePwVisibility = () => {
    setpwVisibility(!pwVisibility)
  }

  const [token, setToken] = useState(null)
  const [countryCode, setCountryCode] = useState("65")
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [password, setPassword] = useState(null)
  const [type, setType] = useState(null)
  const [oid, setOid] = useState(null)
  const [tncChecked, setTncChecked] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      history.push("/dashboard")
      // console.log(obj)
    }

    if (query.get("type")) {
      setType(query.get("type"))
    }

    if (query.get("id")) {
      setOid(query.get("id"))
      localStorage.setItem("redirect_id", query.get("id"))
    }

    if (localStorage.getItem("countryCode")) {
      setCountryCode(localStorage.getItem("countryCode"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log("error", error))

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  const submitLogin = async e => {
    console.log('button pressed')
    // e.preventDefault()

    if (!tncChecked) {
      seterror_alert(true)
      setdynamic_title(props.t("Terms & Conditions and Privacy Policy"))
      setdynamic_description(
        props.t("Please agree Terms & Conditions and Privacy Policy to proceed")
      )
      return
    }

    if (phoneNumber == null) {
      console.log("phone number empty")
      seterror_alert(true)
      setdynamic_title(props.t("Email empty"))
      setdynamic_description(props.t("Please enter your email."))
      return
    }

    if (password == null) {
      console.log("password empty")
      seterror_alert(true)
      setdynamic_title(props.t("Password empty"))
      setdynamic_description(props.t("Please enter your password."))
      return
    }

    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!re.test(phoneNumber)) {
      seterror_alert(true)
      setdynamic_title(props.t("Invalid Email"))
      setdynamic_description(props.t("Please enter a valid email."))
      return
    }

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "member_login")
    urlencoded.append("email", phoneNumber)
    urlencoded.append("password", password)

    // var form = JSON.stringify({
    //   option: "member_login",
    //   email: phoneNumber,
    //   password: password
    // })

    const requestOptions = {
      method: "POST",
      // headers: {
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("login", urlencoded)

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)
    // return;

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        localStorage.setItem("authUser", JSON.stringify(postresponse.data))
        history.push("/dashboard")
      }
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Login failed"))
      setdynamic_description(postresponse.message)
    }
  }

  const [countryData, setCountryData] = useState(null)
  const getCountryList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_country")
    urlencoded.append("order_by", "ASC")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Request country", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setCountryData(postresponse.table)
      if (postresponse.table.length > 0 && countryCode == null) {
        setCountryCode(postresponse.table[0].country_code.replace("+", ""))
      }
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (token) {
      getCountryList()
    }
  }, [token])

  function onCountryCodeChange(e) {
    setCountryCode(e.target.value)
  }

  const [loginEnabled, setLoginEnabled] = useState(false)

  function onPhoneNumberChange(e) {
    setPhoneNumber(e.target.value)
    if (e.target.value != "" && password != null && password.length >= 6)
      setLoginEnabled(true)
    else setLoginEnabled(false)
  }

  function onPasswordChange(e) {
    setPassword(e.target.value)
    if (e.target.value.length >= 6 && phoneNumber != "" && phoneNumber != null)
      setLoginEnabled(true)
    else setLoginEnabled(false)
  }

  const [success_dlg, setsuccess_dlg] = useState(false)
  const [success_dlg_noaction, setsuccess_dlg_noaction] = useState(false)
  const [error_alert, seterror_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  function onClickFBLogin() {
    FB.login(
      function (response) {
        if (response.authResponse) {
          console.log("Welcome!  Fetching your information.... ")
          FB.api(
            "/me?fields=id,name,email,picture.width(300).height(300),friends",
            function (response) {
              console.log("Good to see you", response)
              setFbResponse(response)
            }
          )
        } else {
          console.log("User cancelled login or did not fully authorize.")
        }
      },
      { scope: "public_profile,user_friends" }
    )
  }

  const [fbResponse, setFbResponse] = useState(null)
  const responseFacebook = response => {
    console.log("facebook", response)
    if (response.accessToken) {
      setFbResponse(response)
      localStorage.setItem("fbToken", response.accessToken)
    }
    // setData(response)
    // setPicture(response.picture.data.url)
    // if (response.accessToken) {
    //   setLogin(true)
    // } else {
    //   setLogin(false)
    // }
  }

  useEffect(() => {
    if (fbResponse) loginWithFacebook()
  }, [fbResponse])

  const [uid, setUid] = useState(null)
  const loginWithFacebook = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "login_facebook")
    urlencoded.append("facebook_id", fbResponse.id)
    urlencoded.append("full_name", fbResponse.name)
    urlencoded.append("profile_picture_link", fbResponse.picture.data.url)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("login with fb", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_POST_AUTH,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        console.log("success", postresponse)
        setUid(postresponse.data.member_id)
        localStorage.setItem("authUser", JSON.stringify(postresponse.data))
        if (postresponse.data.country_id != "0") {
          // localStorage.setItem("country", postresponse.data.country_id)
          localStorage.setItem("country", 3)
          // console.log("go to home")
          history.push("/home")
        } else {
          tog_yt()
        }
      }
    } else {
      seterror_alert(true)
      setdynamic_title("Login Facebook failed")
      setdynamic_description(postresponse.message)
    }
  }

  const [modal_yt, setmodal_yt] = useState(false)

  function tog_yt() {
    setmodal_yt(!modal_yt)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const [referralCode, setReferralCode] = useState(null)
  function onReferralCodeChange(e) {
    setReferralCode(e.target.value)
  }

  const [countryId, setCountryId] = useState(null)
  function onCountryIdChange(e) {
    setCountryId(e.target.value)
  }

  const updateCountry = async () => {
    if (countryId == null) {
      console.log("country code empty")
      seterror_alert(true)
      setdynamic_title(props.t("Country empty"))
      setdynamic_description(props.t("Please choose a country."))
      return
    }

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "update_country")
    urlencoded.append("country_id", countryId)
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("login", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_POST_AUTH,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        // localStorage.setItem("country", countryId)
        localStorage.setItem("country", 3)
        if (referralCode) submitReferralCode(uid)
        else {
          history.push("/home")
          // console.log("go to home")
        }
      }
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Login failed"))
      setdynamic_description(postresponse.message)
    }
  }

  const submitReferralCode = async uid => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "apply_referral_code")
    urlencoded.append("member_id", uid)
    urlencoded.append("referral_code", referralCode)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Apply referral", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // console.log("go to home")
      history.push("/home")
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Referral update failed"))
      setdynamic_description(postresponse.message)
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      // call action
      submitLogin()
    }
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Sign In</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0" style={{ backgroundColor: "#27417F" }}>
            {/* <CarouselPage /> */}
            {success_dlg_noaction ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg_noaction(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {success_dlg ? (
              <SweetAlert
                info
                title={dynamic_title}
                onConfirm={() => {
                  history.push("/reset-password")
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {error_alert ? (
              <SweetAlert
                title={dynamic_title}
                danger
                onConfirm={() => {
                  seterror_alert(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            <Col
              // xl={6}
              className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "#27417F",
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content mx-auto"
                style={{ minHeight: "100vh" }}
              >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    {/* <div className=""> */}
                    <Row className="px-3 mb-3 mt-5 mx-0">
                      <Col xs={"auto"} className="pe-0">
                        <Link to="/landing">
                          <button className="btn btn-outline-light btn-rounded btn-block waves-effect waves-light font-size-24 text-white">
                            <i className="bx bx-arrow-back align-middle font-size-24" />
                          </button>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Row
                        className="mx-3 justify-content-center w-100"
                        style={{ marginTop: "150px", maxWidth: "720px" }}
                      >
                        <Form>
                          <Row className="mb-3 px-2">
                            <Col>
                              <p className="font-size-24 text-white font-weight-semibold">
                                {props.t("Sign In")}
                              </p>
                              <span className="font-size-16 text-white">
                                {props.t(
                                  "Log into your account to access all your personalized settings"
                                )}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-3 px-2">
                            <Col>
                              <Input
                                type="text"
                                className="form-control font-size-16 py-3"
                                id="userMobile"
                                placeholder={props.t("Email Address")}
                                style={{ borderRadius: "8px", border: "none" }}
                                onChange={onPhoneNumberChange}
                                onKeyDown={handleKeyDown}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3 px-2 justify-content-center">
                            <Col>
                              <InputGroup>
                                <Input
                                  type={pwVisibility ? "password" : "text"}
                                  className="form-control font-size-16"
                                  id="userMobile"
                                  placeholder={props.t("Password")}
                                  style={{
                                    height: "56px",
                                    border: "none",
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                  }}
                                  onChange={onPasswordChange}
                                  onKeyDown={handleKeyDown}
                                />
                                <InputGroupAddon addonType="append">
                                  <button
                                    type="button"
                                    className="btn float-right"
                                    style={{
                                      backgroundColor: "white",
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      borderTopRightRadius: "8px",
                                      borderBottomRightRadius: "8px",
                                      border: "none",
                                      height: "56px",
                                    }}
                                    onClick={togglePwVisibility}
                                  >
                                    {pwVisibility ? (
                                      <i
                                        className="bx bxs-show align-middle font-size-20 text-muted"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <i
                                        className="bx bxs-chevron-right-square align-middle font-size-20 text-muted"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                </InputGroupAddon>
                              </InputGroup>
                            </Col>
                          </Row>
                        </Form>

                        <Row className="mb-5 px-2">
                          <Col>
                            <Link to="/forgot-password">
                              <button
                                className="btn waves-effect waves-light w-100 font-size-13 text-white"
                                type="submit"
                              >
                                {props.t("Forgot password?")}
                              </button>
                            </Link>
                          </Col>
                        </Row>
                      </Row>
                    </Row>
                    <Row
                      className="mt-auto justify-content-center"
                      style={{ backgroundColor: "#27417F" }}
                    >
                      <Row className="my-3 px-4" style={{ maxWidth: "720px" }}>
                        <Col>
                          {/* <Link to="#"> */}
                          <button
                            className={
                              loginEnabled
                                ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-18 py-3 text-uppercase"
                                : "btn btn-light btn-block waves-effect waves-light w-100 font-size-18 text-muted py-3 text-uppercase"
                            }
                            // type="submit"
                            disabled={!loginEnabled}
                            style={{ borderRadius: "8px" }}
                            onClick={submitLogin}
                          >
                            <strong>{props.t("Sign In")}</strong>
                          </button>
                          {/* </Link> */}
                        </Col>
                      </Row>
                      <Row className="mb-3 px-2 font-size-15 justify-content-center">
                        <Col className="form-check mb-3" xs="auto">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            onChange={e => {
                              setTncChecked(e.target.checked)
                            }}
                          />
                          <label
                            className="form-check-label mb-0 text-center text-white"
                            htmlFor="defaultCheck1"
                          >
                            {props.t(" I agree to the ")}
                            <Link
                              to="/terms-and-conditions"
                              target="_blank"
                              className="text-white"
                            >
                              <u>{props.t("Terms & Conditions")}</u>
                            </Link>
                            {props.t(" and ")}
                            <Link
                              to="/privacy-policy"
                              target="_blank"
                              className="text-white"
                            >
                              <u>{props.t("Privacy Policy")}</u>
                            </Link>
                          </label>
                        </Col>
                      </Row>
                    </Row>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal_yt}
        toggle={() => {
          tog_yt()
        }}
        // className="modal modal-static"
        // centered={true}
        backdrop={"static"}
        id="staticBackdrop"
      >
        {/* <div className="modal-header" style={{ borderBottom: "none" }}>
          <h5 className="modal-title mt-0" id="myModalLabel">
            {"Choose Location"}
          </h5>
          <button
            type="button"
            onClick={() => {
              tog_yt(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}
        <div className="modal-body">
          <AvForm>
            <Row>
              <Label
                className="font-size-15 font-weight-bold text-center"
                style={{ color: "#616E7C" }}
              >
                {props.t("Choose your country")}
              </Label>
              <Col>
                <AvField
                  className="mb-3 form-select font-size-15"
                  type="select"
                  // label={props.t("Participant")}
                  // helpMessage={props.t("Gender Placeholder")}
                  // value={form.country_id}
                  name="country_id"
                  onChange={onCountryIdChange}
                  errorMessage={"Field required"}
                  validate={{ required: { value: true } }}
                  style={{
                    borderRadius: "8px",
                    height: "50px",
                  }}
                >
                  {countryData &&
                    countryData.map((data, index) => (
                      <option key={"country-option-" + index} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                </AvField>
              </Col>
            </Row>
          </AvForm>
          <Row className="justify-content-center">
            <Label
              className="font-size-15 font-weight-bold text-center"
              style={{ color: "#616E7C" }}
            >
              {props.t("Enter your referral code")}
            </Label>
            <Col>
              <InputGroup>
                <Input
                  type="text"
                  className="form-control font-size-15 text-center"
                  id="userReferral"
                  placeholder={props.t("Enter your referral Code")}
                  style={{
                    height: "50px",
                    // border: "none",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  }}
                  onChange={onReferralCodeChange}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              updateCountry()
              // tog_yt()
            }}
            className="btn btn-primary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Apply")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaLogin)

DanawaLogin.propTypes = {
  t: PropTypes.any,
}
